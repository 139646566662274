<template>
  <div class="trial animate__animated animate__fadeIn">
    <p class="title">{{ title }}</p>
    <div class="content">
      <!-- <form class="part left">
        <div class="form-item">
          <input
            type="text"
            placeholder="请输入您的姓名"
            v-model.trim="form.name"
          />
        </div>
        <div class="form-item">
          <input
            type="text"
            placeholder="请输入您的手机号"
            v-model.trim="form.phone"
            maxlength="11"
          />
        </div>
        <div class="form-item code">
          <input
            width="200px"
            type="text"
            placeholder="请输入图形验证码"
            ref="code"
            v-model.trim="form.captchaCode"
            maxlength="4"
          />
          <img :src="captchaSrc" class="captcha cursor-pointer" @click="getCaptcha">
          <p class="error" v-if="error">验证码错误</p>
        </div>
        <div class="form-item code" v-else>
          <input
            width="200px"
            type="text"
            placeholder="请输入手机验证码"
            v-model.trim="form.shortMessage"
            maxlength="6"
          />
          <el-button :disabled="form.phone.length !== 11 || hasSend" class="get-code" @click.prevent="getCode">
            {{sendBtnText}}
          </el-button>
        </div>
        <div class="form-item">
          <input
            type="text"
            placeholder="请输入您所在公司"
            v-model.trim="form.corpName"
          />
        </div>
        <div class="form-item">
          <input
            style="height: 48px;width: 765px"
            type="text"
            placeholder="请输入邮箱"
            v-model.trim="form.email"
          />
        </div>
      </form> -->
      <el-form :model="form" :rules="rules" ref="ruleForm" class="part left">
        <el-form-item prop="name" class="form-item">
          <el-input type="text" v-model.trim="form.name" autocomplete="off" placeholder="请输入您的姓名"></el-input>
        </el-form-item>
        <el-form-item prop="phone" class="form-item">
          <el-input type="text" v-model.trim="form.phone" autocomplete="off" placeholder="请输入您的手机号"
            maxlength="11"></el-input>
        </el-form-item>
        <el-form-item prop="captchaCode" class="form-item code">
          <el-input type="text" v-model.trim="form.captchaCode" autocomplete="off" placeholder="请输入图形验证码"
            maxlength="4"></el-input>
          <img :src="captchaSrc" class="captcha cursor-pointer" @click="getCaptcha" />
        </el-form-item>
        <el-form-item prop="corpName" class="form-item">
          <el-input type="text" v-model.trim="form.corpName" autocomplete="off" placeholder="请输入您所在公司"></el-input>
        </el-form-item>
        <el-form-item prop="email" class="form-item">
          <el-input type="text" v-model.trim="form.email" autocomplete="off" placeholder="请输入邮箱"
            style="height: 48px; width: 780px"></el-input>
        </el-form-item>
      </el-form>
      <p class="submit-btn" @click="submitForm('ruleForm')">提交</p>
      <p id="tel">或致电 010-8210-1180</p>
    </div>

    <el-dialog :visible.sync="visible" width="590px" center append-to-body class="submit-success">
      <div class="dialog-content">
        <template v-if="submitSuccess">
          <div class="commit">
            <i class="el-icon-success" style="color: #00A854; font-size: 24px; margin-right: 10px;" />
            <span style="color: #00A854; font-size: 20px; vertical-align: middle;">提交成功</span>
          </div>
        </template>
        <!-- <template v-else>
          <i
            class="el-icon-warning"
            style="color: #e6a23c; font-size: 24px; margin-right: 10px"
          />
          请完善信息
        </template> -->
        <p class="contact">中知顾问将在 <span style="color: orange"> 3 </span> 个工作日内联系您！</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="confirm" type="primary" style="width: 96px; height: 36px;"
          @click="visible = false">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as api from "@/api";
export default {
  name: "AboutUs",
  props: {
    title: {
      type: String,
      default: '申请试用'
    },
    sceneCode: {
      type: String,
      default: ''
      // 1 "科技金融咨询"
      // 2 "深眸"
      // 3 "瞰见"
      // 4 "专利导航咨询”
      // 5 "数据服务咨询"
      // 6 "注册"
    }
  },
  data() {
    const CheckCaptchaCode = async (rule, value, callback) => {
      if (value) {
        await api
          .checkCaptcha({ captcha: this.form.captchaCode })
          .then(({ success }) => {
            if (success) {
              callback();
              this.isTrue = true; //图形验证码正确
            } else {
              callback(new Error("验证码错误"));
              this.getCaptcha();
            }
          });
      }
    };
    const Checkemail = async (rule, value, callback) => {
      if (value) {
        if (
          /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
            value
          )
        ) {
          callback();
        } else {
          callback(new Error("邮箱格式错误"));
        }
      }
    };
    const CheckPhone = async (rule, value, callback) => {
      if (value) {
        if (
          /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
            value
          )
        ) {
          callback();
        } else {
          callback(new Error("手机号格式错误"));
        }
      }
    };
    return {
      form: {
        name: "",
        phone: "",
        email: "",
        corpName: "",
        captchaCode: "",
      },
      hasSend: false, //是否已经发送验证码
      countDown: 60, //倒计时
      captchaSrc: "",
      visible: false,
      submitSuccess: false,
      // visibleCapcha: true //是否显示图形验证码
      isTrue: false, //图形验证码是否错误,一开始为错误
      rules: {
        name: [{ required: true, message: "请输入您的姓名", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入您的手机号", trigger: "blur" },
          { validator: CheckPhone, trigger: "blur" },
        ],
        captchaCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { validator: CheckCaptchaCode, trigger: "blur" },
        ],
        corpName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        email: [
          { required: true, message: "请输入您的邮箱", trigger: "blur" },
          { validator: Checkemail, trigger: "blur" },
        ],
      },
      dict: {
        1: '解决方案咨询',
        2: '申请深眸体验',
        3: '申请瞰见体验',
        4: '咨询专利导航服务',
        5: '数据服务咨询',
        6: '申请试用'
      }
    };
  },
  mounted() {
    this.getCaptcha();
    //挂载时监听图形验证码的输入
    // this.$refs.code.addEventListener('input', async () => {
    //   //当图形验证码和手机号填写完毕时自动发送验证码
    //   if(this.$refs.code.value.length === 4 && this.form.phone){
    //     const { success } = await api.checkCaptcha({ captcha: this.code })
    //     if (success) {
    //     // todo: 让后端把注册接口这个字段改为非必填
    //     this.form.content = '注册申请'
    //     this.visibleCapcha = false
    //   }else{
    //     this.$message.error('验证码错误')
    //     this.getCaptcha()
    //   }
    //   }
    // })
  },
  computed: {
    sendBtnText() {
      return this.hasSend ? `${this.countDown}s` : "发送验证码";
    },
  },
  methods: {
    getCode() {
      this.hasSend = true;
      const timer1 = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown--;
        } else {
          this.hasSend = false;
          this.countDown = 60;
          clearInterval(timer1);
        }
      }, 1000);
      api
        .verificationCodeSend({
          mobile: this.form.phone,
          temId: 3,
        })
        .then(({ code }) => {
          if (code === "0000") {
            message.success("验证码已发送");
          }
        });
    },
    async getCaptcha() {
      const { data } = await api.getCaptcha();
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "image/jepg" })
      );
      this.captchaSrc = url;
    },
    async submitForm(formName) {
      // 判断form是否有字段为空
      // for (const key in this.form) {
      //   if (!this.form[key]) {
      //     this.submitSuccess = false
      //     this.visible = true
      //     return
      //   }
      // }
      // const { success } = await api.checkCaptcha({ captcha: this.form.captchaCode })
      // if (success) {
      let validateFieldList = [];
      this.$refs[formName].validateField(
        ["name", "phone", "corpName", "email"],
        (valid) => {
          console.log('valid', valid)
          if (!valid) {
            //校验通过走这里,每通过一次,会往这个数组里加一个""
            validateFieldList.push(valid);
            if (
              validateFieldList.length == 4 &&
              validateFieldList.every((item) => item === "" && this.isTrue)
            ) {
              //这里写校验通过的业务逻辑
              this.form.content = this.dict[this.$props.sceneCode];//必填
              api.applyForm({ ...this.form, sceneCode: this.$props.sceneCode }).then(({ success }) => {
                if (success) {
                  this.submitSuccess = true;
                  this.visible = true;
                  this.getCaptcha();
                  this.$refs[formName].resetFields()//重置表单数据
                  this.isTrue = false;
                }
              });
            }
          }
          // this.$refs[formName].clearValidate("captchaCode");
          // console.log(valid)
          // if (valid && this.isTrue) {
          //   this.form.content = '注册申请'
          //   delete this.form.captchaCode;
          //   api.applyForm(this.form).then(({ success }) => {
          //     if (success) {
          //       this.submitSuccess = true
          //       this.visible = true
          //       this.getCaptcha()
          //       this.isTrue = false
          //       this.rules.captchaCode = rule
          //     }
          //   })
          // }
        }
      );
    },
    //   else {
    //     // this.$message.error('验证码错误')
    //     this.getCaptcha()
    //     console.log(111)
    //   }
    // }
  },
};
</script>
<style lang='scss' scoped>
.dialog-content {
  font-size: Translate(20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .contact {
    width: 800px;
    padding: Translate(24px) 0 Translate(66px) 0;
    font-size: Translate(16px);
    color: #333333;
    text-align: center;
    line-height: 24px;
    font-weight: 400;
  }
}

.trial {
  background: #f5f6f9;
  padding-top: Translate(80px);

  .title {
    font-size: Translate(32px);
    color: #333;
    font-weight: 500;
  }

  // width: $container-width;
  position: relative;
  margin: 0 auto;
  // background-color: #fff;
  // padding: 150px 120px;
  box-sizing: border-box;

  .content {
    padding: Translate(60px) 0 Translate(80px);
    width: Translate(780px);
    margin: 0 auto;

    .submit-btn {
      margin-top: Translate(24px);
      margin-bottom: Translate(18px);
      display: inline-block;
      text-align: center;
      width: Translate(280px);
      height: Translate(48px);
      background-color: #4088eb;
      color: #fff;
      border-radius: Translate(24px);
      line-height: Translate(48px);
      cursor: pointer;

      &:hover {
        background-color: #337ee5;
      }
    }

    .part {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      text-align: left;

      .code {
        position: relative;

        .captcha {
          width: Translate(80px);
          position: absolute;
          right: 10px;
          top: 0;
          bottom: 0;
          margin: auto;
        }

        .error {
          position: absolute;
          color: #f56c6c;
          bottom: -35px;
          font-size: Translate(12px);
          line-height: Translate(20px) px;
          font-weight: 400;
        }
      }
    }

    form {

      // border-right: 1px solid #E8E8E8;
      .form-item {
        display: flex;
        margin-bottom: Translate(26px);
      }

      label {
        color: #333;
        font-size: 38px;
        display: inline-block;
        width: 220px;
        padding-top: 20px;

        span {
          color: #e94c3d;
        }
      }

      input,
      textarea {
        font-size: Translate(14px);
        display: inline-block;
        width: Translate(362px);
        height: Translate(48px);
        border: 1px solid #e8e8e8;
        border-radius: 10px;
        padding-left: 30px;
        outline: none;
        overflow: auto;
        resize: none;

        &::placeholder {
          color: #999;
          font-size: 36px;
        }
      }

      textarea {
        height: 240px;
        padding-top: 30px;
      }
    }

    .right {

      // padding-left: 220px;
      .title {
        display: flex;
        align-items: center;
        color: #4088eb;
        font-size: 46px;
        padding-bottom: 30px;

        img {
          width: 60px;
          padding-right: 40px;
        }
      }

      .main {
        padding-bottom: 80px;
        display: flex;

        img {
          width: 280px;
          margin-right: 40px;
          padding: 20px;
          border: 1px solid #e5e6e9;
        }
      }

      p {
        margin: 0;
        line-height: 1.8;
      }
    }
  }
}

#tel {
  font-size: Translate(14px);
  color: #333333;
  text-align: center;
  font-weight: 400;
  // padding-top: Translate(18px);
  // padding-bottom: Translate(99px);
}

.get-code {
  position: absolute;
  font-size: Translate(14px);
  color: #4088eb;
  font-weight: 400;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  background: #fff;
}

.el-input {
  width: 380px;
}

::v-deep .form-item .el-input__inner {
  height: 48px;
  border: 1px solid #e3e6e9;
}

::v-deep .dialog-content .el-icon-success {
  &::before {
    vertical-align: -4px;
  }
}

.dialog-footer {
  .confirm {
    background: #4088EB;

    &:hover {
      background: #337ee5;
    }
  }
}
</style>
<style lang="scss">
.submit-success{
  .el-dialog{
    .el-dialog__header{
      padding-top: 46px;
      background: #FAFAFA;
    }
  }
  .el-dialog__body {
      padding-top: 50px;
      padding-bottom: 0;
    }
}
</style>
